import React, { useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';

const StockIssueReport = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    // Updated data for the table
    const [data, setData] = useState([
        {
            id: 1,
            category: 'Cement',
        },
        {
            id: 2,
            category: 'Sand',
        },
        {
            id: 3,
            category: 'Steel',
        },
        {
            id: 4,
            category: 'Bricks',
        },
    ]);

    // Updated table columns
    const columns = [
        {
            accessorKey: 'id',
            header: 'S.No',
        },
        {
            accessorKey: 'category',
            header: 'Item Category',
        },
        {
            accessorKey: 'action',
            header: 'Action',
            Cell: ({ row }) => (
                <button
                    className="text-blue-500 underline hover:text-blue-700"
                    onClick={() => handleAction(row)}
                >
                    View
                </button>
            ),
        },
    ];

    // Handle action button
    const handleAction = (row) => {
        alert(`Viewing details for ${row.original.category}`);
    };

    return (
        <div className="p-6">
            <div className='flex flex-row items-center justify-between bg-white p-3 mb-3 rounded-lg'>
                <h1 className="text-2xl font-bold mb-0 text-gray-800">Stock Issue</h1>
                <button
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    onClick={() => {
                        navigate('/stock-issue-form');
                    }}
                >
                    Item Issue
                </button>
            </div>

            {/* Table */}
            <MaterialReactTable
                columns={columns}
                data={data}
                enablePagination
                enableBottomToolbar
                muiTableBodyRowProps={{
                    hover: true,
                }}
            />
        </div>
    );
};

export default StockIssueReport;