import React, { useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';

const MaterialInOutReport = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    // Sample data for the table
    const [data, setData] = useState([
        {
            id: 1,
            date: '2024-12-30',
            name: 'Cement',
            location: 'Warehouse A',
            status: 'In',
        },
        {
            id: 2,
            date: '2024-12-29',
            name: 'Sand',
            location: 'Warehouse B',
            status: 'Out',
        },
        {
            id: 1,
            date: '2024-12-30',
            name: 'Cement',
            location: 'Warehouse A',
            status: 'In',
        },
        {
            id: 2,
            date: '2024-12-29',
            name: 'Sand',
            location: 'Warehouse B',
            status: 'Out',
        },
        {
            id: 1,
            date: '2024-12-30',
            name: 'Cement',
            location: 'Warehouse A',
            status: 'In',
        },
        {
            id: 2,
            date: '2024-12-29',
            name: 'Sand',
            location: 'Warehouse B',
            status: 'Out',
        },
        {
            id: 1,
            date: '2024-12-30',
            name: 'Cement',
            location: 'Warehouse A',
            status: 'In',
        },
        {
            id: 2,
            date: '2024-12-29',
            name: 'Sand',
            location: 'Warehouse B',
            status: 'Out',
        },
        {
            id: 1,
            date: '2024-12-30',
            name: 'Cement',
            location: 'Warehouse A',
            status: 'In',
        },
        {
            id: 2,
            date: '2024-12-29',
            name: 'Sand',
            location: 'Warehouse B',
            status: 'Out',
        },
        {
            id: 1,
            date: '2024-12-30',
            name: 'Cement',
            location: 'Warehouse A',
            status: 'In',
        },
        {
            id: 2,
            date: '2024-12-29',
            name: 'Sand',
            location: 'Warehouse B',
            status: 'Out',
        },
        {
            id: 1,
            date: '2024-12-30',
            name: 'Cement',
            location: 'Warehouse A',
            status: 'In',
        },
        {
            id: 2,
            date: '2024-12-29',
            name: 'Sand',
            location: 'Warehouse B',
            status: 'Out',
        },
    ]);

    // Table columns
    const columns = [
        {
            accessorKey: 'id',
            header: 'S.No',
        },
        {
            accessorKey: 'date',
            header: 'Date',
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'location',
            header: 'Location',
        },
        {
            accessorKey: 'status',
            header: 'Status',
        },
        {
            accessorKey: 'action',
            header: 'Action',
            Cell: ({ row }) => (
                <button
                    className="text-blue-500 underline hover:text-blue-700"
                    onClick={() => handleAction(row)}
                >
                    View
                </button>
            ),
        },
    ];

    // Handle action button
    const handleAction = (row) => {
        alert(`Viewing details for ${row.original.name}`);
    };

    return (
        <div className="p-6">
            <div className='flex flex-row items-center justify-between bg-white p-3 mb-3 rounded-lg'>
                <h1 className="text-2xl font-bold mb-0 text-gray-800">Material In/Out Report</h1>
                <button
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    onClick={() => {
                        navigate('/material-in-out-form');
                    }}
                >
                    Add Form
                </button>
            </div>

            {/* Table */}
            <MaterialReactTable
                columns={columns}
                data={data}
                enablePagination
                enableBottomToolbar
                muiTableBodyRowProps={{
                    hover: true,
                }}
            />
        </div>
    );
};

export default MaterialInOutReport;