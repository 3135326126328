import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import api from './Axios/api';
import IndentDetailTable from './DataTables/IndentDetailTable';
import BackBtn from './common/BackBtn';

const IndentDetails = () => {

    const [indent, setIndent] = useState({});
    const { id } = useParams();
    const [loading, setLoading] = useState(true);

    const { state } = useLocation();



    const handleIndentDetails = async (id) => {
        try {
            setLoading(true)
            let res;
            if (state?.return) {
                res = await api.get(`/purchase/return-indent-item-list/${id}`)
            } else {
                res = await api.get(`/indent/get/${id}`);
            }
            setIndent(res.data[0])
            setLoading(false)
        } catch (error) {
            console.log(error)
        }

    }


    const formatDate = (date) => {
        const dt = new Date(date).toLocaleDateString();
        return dt;
    }


    useEffect(() => {
        handleIndentDetails(id)
    }, [id])

    return (
        <div className='w-full flex items-center justify-start flex-col '>
            <div className="w-[90%] mt-4 overflow-x-auto ">
                <div className="h-10 px-2 flex justify-between items-center text-violet-500 font-bold rounded-md bg-[#fdfdfd] shadow mb-2">
                    <div>Indent Details</div>
                    <BackBtn path={"/"} />
                </div>
                <div className="inline-block min-w-full bg-white shadow rounded mb-1">
                    <table className="w-full divide-y divide-gray-200">
                        <tbody className="divide-y divide-gray-200">
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Indent Factory
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {indent.indent_branch_name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Indent By
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">

                                    {indent.indent_by_name}
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Indent Date
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {formatDate(indent.indent_date)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Indent Remark
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {indent.indent_remark}
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Indent No.
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {indent.indent_number}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Indent Status
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                                    {indent.indent_status}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {
                    loading ?
                        <div>Loading....</div> :
                        <IndentDetailTable data={indent?.items} type={state?.return} />
                }

            </div>
        </div>
    );
};

export default IndentDetails;