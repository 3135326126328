import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import api from '../Axios/api';
import { FileText, Pencil } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import * as XLSX from 'xlsx'; // Import the xlsx library

const ReceiptNoteReport = () => {
  const navigation = useNavigate();
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [excelData, setExcelData] = useState([]);

  const getData = async () => {
    try {
      const res = await api.get(`/receipt-note/get`);
      setData(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };




  const getDetailsForExcel = async () => {
    try {
      const selectedIds = Object.keys(rowSelection)
        .filter(index => rowSelection[index])
        .map(index => data[parseInt(index)]._id);
      console.log(selectedIds)
      const res = await Promise.all(
        selectedIds.map(async (id) => ((await api.get(`receipt-note/get-excel-details/${id}`)).data))
      );
      const allData = res.flat(); // Use flat() to merge nested arrays
      setExcelData(allData)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDetailsForExcel()
  }, [rowSelection])



  const handlePrint = (cell) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `https://polybonderps.site/purchase-print/receipt-print.php`;
    form.target = '_blank';

    const receiptId = document.createElement('input');
    receiptId.type = 'hidden';
    receiptId.name = 'receiptId';
    receiptId.value = cell.getValue();
    form.appendChild(receiptId);

    const dataInput = document.createElement('input');
    dataInput.type = 'hidden';
    dataInput.name = 'token';
    dataInput.value = JSON.stringify(Cookies.get('access_token'));
    form.appendChild(dataInput);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(data)

  const columns = useMemo(
    () => [
      {
        accessorKey: 'entry_date',
        header: 'Date',
        size: 150,
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
      },
      {
        accessorKey: 'receipt_no',
        header: 'Receipt No.',
        size: 150,
      },
      {
        accessorKey: 'po_number',
        header: 'PO NO.',
        size: 150,
      },
      {
        accessorKey: 'vendor_name',
        header: 'PARTY',
        size: 150,
      },
      {
        accessorKey: 'staff_name',
        header: 'Created by',
        size: 150,
      },
      {
        accessorKey: '_id',
        header: 'Action',
        size: 200,
        Cell: ({ cell }) => (
          <div className='flex items-center gap-3'>
            <div onClick={() => handlePrint(cell)}>
              <button><FileText size={20} /></button>
            </div>
            <div onClick={() => navigation(`/create-receipt-form`, { state: { receiptId: cell.getValue() } })}>
              <button><Pencil size={18} /></button>
            </div>
          </div>
        ),
      },
    ],
    []
  );



  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableRowSelection: true,
    getRowId: (row) => row.userId, //give each row a more useful id
    onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
    state: { rowSelection },
    renderToolbarAlertBannerContent: ({ table }) => {
      return (
        <div className='flex gap-2 px-1 mt-1'>
          <button className='p-1 bg-[#EEEDEB] rounded-t-lg shadow-sm' onClick={exportToExcel}>
            <span className="text-sm">Export Excel</span>
          </button>
        </div>
      )
    },
  });

  const exportToExcel = () => {
    const formatedData = excelData.map((row, index) => ({
      "VCH NO.": row.receipt_no,
      "VCH Date": row.entry_date.split("T")[0],
      "GSTIN": row.vendor_gstno,
      "Customer Name": row.vendor_name,
      "Voucher Type": "Receipt Note",
      "Product Name": row.receipt_item_name,
      "Quantity": row.receipt_item_qty,
      "Rate": row.receipt_item_rate,
      "Disc %": row.receipt_item_disc,
      "Amount": row.receipt_item_total_with_gst,
      "CGST %": row.receipt_charge_gst1 / 2,
      "CGST": (row.receipt_item_total_with_gst * ((row.receipt_charge_gst1 / 2) / 100)),
      "SGST %": row.receipt_charge_gst1 / 2,
      "SGST": (row.receipt_item_total_with_gst * ((row.receipt_charge_gst1 / 2) / 100)),
      "Refrence No.": row.receipt_no,
      "Refrence Date": row.entry_date.split("T")[0],
      "Purchase Ledger": row.purchase_ledger_name,
      "Terms of Payment": row.terms_of_payment,
      "Other": row.other_references,
      "Terms Of Delivery1": row.terms_of_delivery.split(";")[0],
      "Terms Of Delivery2": row.terms_of_delivery.split(";")[1],
      "Terms Of Delivery3": row.terms_of_delivery.split(";")[2],
      "PO No.": row.po_number[0],
      "PO Date": row.order_date[0]?.split("T")[0],
      "Bill/ LR No.": "",
      "Motor Vehicle No.": row.vehicle_no,
      "Driver mobile No.": row.driver_mobile_no,
      "Challan No.": row.challan_no,
      "Challan Date": row.challan_date.split("T")[0],
      "weight slip no": row.weight_slip_no,
      "weight slip date": row.weight_slip_date.split("T")[0],
      "Gate Entry": row.gate_entry_no,
      "Stores Remarks": row.stores_remarks,
      "Payment Terms": row.terms_of_payment,
      "Narration": row.receipt_narration,
    }))
    // Use excelData directly to create the worksheet
    const worksheet = XLSX.utils.json_to_sheet(formatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Receipt Notes');

    // // Download the Excel file
    XLSX.writeFile(workbook, 'ReceiptNoteReport.xlsx');
  };




  return (
    <div className="flex-1 px-5 w-full justify-center items-center">
      <div>
        <div className="bg-[#fdfdfd] border mt-4 p-2 flex w-full justify-between items-center rounded-md shadow">
          <div className="flex rounded-md items-center w-1/2">
            <h2 className="font-bold text-violet-500">Receipt Note Report</h2>
          </div>
          <div onClick={() => { navigation('/create-receipt-form'); }} className='text-white font-bold rounded-md px-3 bg-violet-600 hover:bg-violet-700 hover:ease-in shadow border flex items-center cursor-pointer transition-colors'>
            Receipt Note Form
          </div>
        </div>
      </div>
      <div className="w-full min-h-[500px] my-2 rounded-md shadow bg-[#fdfdfd]">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default ReceiptNoteReport;
