import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import api from "../Axios/api";


const AddItems = () => {
    // const data = [
    //     { sn: 1, title: 'Diesel', category: 'Fuel & Firewood', brand: 'None', unit: 'LTR', fac: 'POLYBOND INSULATION' },
    //     { sn: 2, title: 'FIRE WOOD', category: 'Fuel & Firewood', brand: 'None', unit: 'K.G.', fac: 'POLYBOND INSULATION' },
    // ]

    const [rowCount, setRowCount] = useState(0);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [factoryLocation, setFactoryLocation] = useState([]);
    const [brandArray, setBrandArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);

    const [editId, setEditId] = useState(null);
    const [inputs, setInputs] = useState({
        item_title: "",
        item_category: "",
        item_brand: "",
        unit: "",
        gst: "",
        factory: ""
    })

    const [append, setAppend] = useState({
        category: "",
        brand: "",
        factory: ""
    });

    const [itemsData, setItemsData] = useState([]);

    const [table1Data, setTable1Data] = useState([
        { sn: 1, chargeName: "Charge A", factory: "Factory 1" },
        { sn: 2, chargeName: "Charge B", factory: "Factory 2" },
    ]);

    const [table2Data, setTable2Data] = useState([
        { sn: 1, ledgerName: "Ledger X", factory: "Factory A" },
        { sn: 2, ledgerName: "Ledger Y", factory: "Factory B" },
    ]);

    const [chargeOrLedger, setChargeOrLedger] = useState(null);
    const [popupInputs, setPopupInputs] = useState({
        name: "",
        factory: "",
    });

    const table1Columns = useMemo(() => [
        {
            accessorKey: "sn",
            header: "SN",
            size: 50,
        },
        {
            accessorKey: "chargeName",
            header: "Charge Name",
        },
        {
            accessorKey: "factory",
            header: "Factory",
        },
    ], []);

    const table2Columns = useMemo(() => [
        {
            accessorKey: "sn",
            header: "SN",
            size: 50,
        },
        {
            accessorKey: "ledgerName",
            header: "Ledger Name",
        },
        {
            accessorKey: "factory",
            header: "Factory",
        },
    ], []);

    const getItems = async () => {
        try {
            const { pageIndex, pageSize } = pagination;
            const response = await api.get(`purchase-item/get-item/?limit=${pageSize}&page=${pageIndex + 1}`);
            setItemsData(response.data.items || []);
            setRowCount(response.data.size || 0);
            console.log(response.data.items);
        } catch (error) {
            console.log(error)
        }
    }

    const addItem = async (e) => {
        console.log("Append", append);
        try {
            e.preventDefault();
            const { factory, item_category, item_brand, item_title, unit, gst } = inputs;
            const payload = {
                item_title: item_title,
                item_unit: unit,
                item_gst: gst,
                item_itemcat: item_category,
                item_brand: item_brand,
                item_branch: factory,
            };
            const response = await api.post(`/purchase-item/add-items`, payload);
            const newItem = response.data;
            newItem.item_branch_name = append.factory;
            newItem.item_brand_name = append.brand;
            newItem.item_cat_name = append.category;
            setItemsData((prevData) => [newItem, ...prevData]);
            console.log(itemsData.length, itemsData);
            resetHandler();
        } catch (error) {
            console.log(error);
        }
    };


    const handleEdit = (row) => {
        setEditId(row.original._id);
        setInputs({
            item_title: row.original.item_title,
            item_category: row.original.item_itemcat,
            item_brand: row.original.item_brand,
            unit: row.original.item_unit,
            gst: row.original.item_gst,
            factory: row.original.item_branch,
        })
        console.log(row);
    }

    const editItem = async (e, id) => {
        e.preventDefault();
        const { factory, item_category, item_brand, item_title, unit, gst } = inputs;
        const payload = {
            item_title: item_title,
            item_unit: unit,
            item_gst: gst,
            item_itemcat: item_category,
            item_brand: item_brand,
            item_branch: factory,
        };
        try {
            const response = await api.put(`/purchase-item/update-items/${id}`, payload);
            console.log(response.data);
            resetHandler();
            getItems();
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async (row) => {
        const id = row.original._id;
        try {
            const response = await api.delete(`/purchase-item/delete-items/${id}`);
            console.log(response.data);
            getItems();
        } catch (error) {
            console.log(error);
        }
    }

    const resetHandler = () => {
        setInputs({
            item_title: "",
            item_category: "",
            item_brand: "",
            unit: "",
            gst: "",
            factory: ""
        });
        setEditId(null);
    };

    const getFactories = async () => {
        try {
            const response = await api.get(`/factory-location/get-factory-location`);
            setFactoryLocation(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getBrands = async () => {
        try {
            const response = await api.get(`/brand/get`);
            setBrandArray(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getCategories = async () => {
        try {
            const response = await api.get(`/purchase-item-category/get-name`);
            setCategoryArray(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getTable1Data = async () => {
        try {
            const response = await api.get(`/api/table1-data`);
            setTable1Data(response.data || []);
        } catch (error) {
            console.log(error);
        }
    };

    const getTable2Data = async () => {
        try {
            const response = await api.get(`/api/table2-data`);
            setTable2Data(response.data || []);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getItems();
        getFactories();
        getBrands();
        getCategories();
        getTable1Data();
        getTable2Data();
    }, [pagination])

    const columns = useMemo(() =>
        [
            {
                accessorKey: 'sn',
                header: 'SN',
                size: 50,
                Cell: ({ row }) => row.index + 1,
            },
            { accessorKey: 'item_title', header: 'Title' },
            { accessorKey: 'item_cat_name', header: 'Category' },
            { accessorKey: 'item_brand_name', header: 'Brand' },
            { accessorKey: 'item_unit', header: 'Unit' },
            // { accessorKey: 'status', header: 'Status' },
            { accessorKey: 'item_branch_name', header: 'Fac' },
            {
                accessorKey: 'action',
                header: 'Action',
                Cell: ({ row }) => (
                    <div className="flex space-x-2">
                        <button
                            className="text-blue-600 hover:underline"
                            onClick={() => handleEdit(row)}
                        >
                            Edit
                        </button>
                        <button
                            className="text-red-600 hover:underline"
                            onClick={() => handleDelete(row)}
                        >
                            Delete
                        </button>
                    </div>
                ),
            },
        ]
        , [])

    return (
        <div className="flex flex-col relative">
            <div className="flex flex-col lg:flex-row gap-6 p-6 h-[680px]">
                {chargeOrLedger && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
                )}
                {/* Table Section */}
                <div className="bg-white p-6 rounded-lg shadow-md lg:flex-grow overflow-y-scroll scrollbar-none">
                    <h2 className="text-xl font-semibold mb-4 ">All Item </h2>
                    <MaterialReactTable
                        columns={columns}
                        data={itemsData}
                        manualPagination
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10, 20, 50, 100],
                        }}
                        state={{
                            pagination,
                        }}
                        rowCount={rowCount}
                        onPaginationChange={setPagination}
                    />
                </div>
                {/* Form Section */}
                <div className="bg-white p-6 rounded-lg shadow-md lg:w-1/3">
                    <h2 className="text-xl font-semibold mb-4">{editId ? "Edit Item" : "Item Form"}</h2>
                    <form className="space-y-4" onSubmit={(e) => {
                        e.preventDefault();
                        if (editId) {
                            editItem(e, editId);
                        } else {
                            addItem(e);
                        }
                    }}>
                        {/* Factory Dropdown */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Factory<span className="text-red-600">*</span>
                            </label>
                            <select
                                onChange={(e) => {
                                    setInputs((prev) => ({ ...prev, factory: e.target.value }))
                                    setAppend((prev) => ({ ...prev, factory: e.target.selectedOptions[0].text }))
                                }}
                                value={inputs.factory}
                                className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="">Select Factory</option>
                                {factoryLocation.map((factory) => (
                                    <option key={factory._id} value={factory._id}>
                                        {factory.fectory_loca_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Item Category Dropdown */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Item Category<span className="text-red-600">*</span>
                            </label>
                            <select
                                onChange={(e) => {
                                    setInputs((prev) => ({ ...prev, item_category: e.target.value }))
                                    setAppend((prev) => ({ ...prev, category: e.target.selectedOptions[0].text }))
                                }}
                                value={inputs.item_category}
                                className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="">Select Item Category</option>
                                {categoryArray.map((category) => (
                                    <option key={category._id} value={category._id}>
                                        {category.itemcat_title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Item Brand Dropdown */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Item Brand<span className="text-red-600">*</span>
                            </label>
                            <select
                                onChange={(e) => {
                                    setInputs((prev) => ({ ...prev, item_brand: e.target.value }))
                                    setAppend((prev) => ({ ...prev, brand: e.target.selectedOptions[0].text }))
                                }}
                                value={inputs.item_brand}
                                className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="">Select Item Brand</option>
                                {brandArray.map((brand) => (
                                    <option key={brand._id} value={brand._id}>
                                        {brand.brand_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Item Title */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Item Title<span className="text-red-600">*</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Item Title"
                                onChange={(e) => setInputs((prev) => ({ ...prev, item_title: e.target.value }))}
                                value={inputs.item_title}
                                className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>

                        {/* Unit */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Unit<span className="text-red-600">*</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Unit"
                                onChange={(e) => setInputs((prev) => ({ ...prev, unit: e.target.value }))}
                                value={inputs.unit}
                                className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>

                        {/* GST */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                GST (in %)
                            </label>
                            <input
                                type="text"
                                placeholder="Enter GST"
                                onChange={(e) => setInputs((prev) => ({ ...prev, gst: e.target.value }))}
                                value={inputs.gst}
                                className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>

                        {/* Submit and Reset Buttons */}
                        <div className="flex gap-4">
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                            >
                                Submit
                            </button>
                            <button
                                type="reset"
                                onClick={resetHandler}
                                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                            >
                                Reset
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="p-2 mx-6 rounded-lg flex flex-row justify-between gap-4 mb-4">
                {/* Table 1 */}
                <div className="w-1/2 p-4 bg-white shadow-md rounded-md">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold">Table 1</h2>
                        <button
                            className="bg-purple-500 text-white px-2 py-1 rounded-md hover:bg-purple-600"
                            onClick={() => setChargeOrLedger("Charge")}
                        >
                            Add Extra Charge
                        </button>
                    </div>
                    <MaterialReactTable columns={table1Columns} data={table1Data} />
                </div>

                {/* Table 2 */}
                <div className="w-1/2 p-4 bg-white shadow-md rounded-md">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold">Table 2</h2>
                        <button
                            className="bg-purple-500 text-white px-2 py-1 rounded-md hover:bg-purple-600"
                            onClick={() => setChargeOrLedger("Ledger")}
                        >
                            Add Ledger
                        </button>
                    </div>
                    <MaterialReactTable columns={table2Columns} data={table2Data} />
                </div>

                {chargeOrLedger && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-[30rem]">
                            <h2 className="text-xl font-semibold mb-4">
                                {chargeOrLedger === "Charge" ? "Add Extra Charge" : "Add Ledger"}
                            </h2>
                            <form
                                className="space-y-4"
                            // onSubmit={handlePopupSubmit}
                            >
                                {/* Name Input */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        {chargeOrLedger === "Charge" ? "Extra Charge Name" : "Ledger Name"}
                                        <span className="text-red-600">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Name"
                                        value={popupInputs.name}
                                        onChange={(e) =>
                                            setPopupInputs((prev) => ({ ...prev, name: e.target.value }))
                                        }
                                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>

                                {/* Factory Dropdown */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Factory Name<span className="text-red-600">*</span>
                                    </label>
                                    <select
                                        value={popupInputs.factory}
                                        onChange={(e) =>
                                            setPopupInputs((prev) => ({ ...prev, factory: e.target.value }))
                                        }
                                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        <option value="">Select Factory</option>
                                        {factoryLocation.map((factory) => (
                                            <option key={factory._id} value={factory._id}>
                                                {factory.fectory_loca_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Submit and Cancel Buttons */}
                                <div className="flex gap-4 justify-end">
                                    <button
                                        type="button"
                                        onClick={() => setChargeOrLedger(null)}
                                        className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded-md"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddItems;
