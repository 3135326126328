import React, { useEffect } from "react";
import Sidebar, { SidebarItem } from "./Sidebar";
import {
  LifeBuoy,
  ReceiptText,
  ShoppingBasket,
  ShoppingBag,
  GitCompare,
  PackageCheck,
  SquareKanban,
  LayoutDashboard,
  Settings,
  FilePenLine,
  IndianRupee,
  Construction,
  Undo2,
  Book,
  Minus,
  AlignVerticalJustifyEnd,
  FolderPlus,

} from "lucide-react";

const SideNav = () => {
  useEffect(() => {
    const el = document.querySelector(".navList").children;
    for (const iterator of el) {
      iterator.addEventListener("click", function () {
        for (const element of el) {
          element.classList.remove("bg-gradient-to-tr");
          element.classList.remove("from-indigo-200");
          element.classList.remove("to-indigo-100");
          element.classList.remove("text-indigo-800");
        }
        this.classList.add("bg-gradient-to-tr");
        this.classList.add("from-indigo-200");
        this.classList.add("to-indigo-100");
        this.classList.add("text-indigo-800");
      });
    }
  }, []);
  return (
    <Sidebar>
      <SidebarItem
        icon={<LayoutDashboard size={20} />}
        text="Dashboard"
        alert
        to="/dashboard"
      />
      <SidebarItem icon={<SquareKanban size={20} />} text="Indent" to="/" />
      <SidebarItem
        icon={<Undo2 size={20} />}
        text="Return List"
        to="/returnList"
      />
      <SidebarItem
        icon={<GitCompare size={20} />}
        text="Quotation Comparison"
        to="/quote-comparison"
      />
      <SidebarItem
        icon={<ShoppingBag size={20} />}
        text="Approval List"
        alert
        to="/approval-list"
      />
      <SidebarItem
        icon={<PackageCheck size={20} />}
        text="Created Po"
        to="/created-po-list"
      />
      <SidebarItem
        icon={<ReceiptText size={20} />}
        text="Receipt Note"
        to="/receipt-note-report"
      />
      <SidebarItem
        icon={<FilePenLine size={20} />}
        text="Purchase Entry"
        to="/purchase-entry"
      />
      <SidebarItem
        icon={<IndianRupee size={20} />}
        text="Payment"
        to="/purchase-payment"
      />
      <SidebarItem
        icon={<FilePenLine size={20} />}
        text="Consumption Form"
        to="/consumption-form"
      />
      <SidebarItem
        icon={<FilePenLine size={20} />}
        text="Production Form"
        to="/production-form"
      />
      <SidebarItem
        icon={<Book size={20} />}
        text="Report"
        to="/report"
      />
      <SidebarItem
        icon={<Minus size={20} />}
        text="Master"
      // to="/report"
      />
      <SidebarItem
        icon={<FolderPlus size={20} />}
        text="Add Category"
        to="/add-category"
      />
      <SidebarItem
        icon={<AlignVerticalJustifyEnd size={20} />}
        text="Add Items"
        to="/add-items"
      />
      <SidebarItem
        icon={<AlignVerticalJustifyEnd size={20} />}
        text="Material In Out"
        to="/material-in-out"
      />
      <SidebarItem
        icon={<AlignVerticalJustifyEnd size={20} />}
        text="Stock Issue"
        to="/stock-issue"
      />

    </Sidebar>
  );
};

export default SideNav;
