import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import Button from '@mui/material/Button';
import api from './Axios/api';
import { useNavigate } from 'react-router-dom';

const Report = () => {
    // Transform data
    const [reportData, setReportData] = useState();

    const filterFun = (data) => {
        const filteredData = data.map((entry) => {
            let itemSource = null;

            let consumptionTotalAmount = entry.consumption?.[0]?.totalAmount || 0;
            let productionTotalAmount = entry.production?.[0]?.totalAmount || 0;
            let ovarAllTotal = `${consumptionTotalAmount} || ${productionTotalAmount}`;

            if (entry.consumption?.length > 0) {
                const firstConsumption = entry.consumption[0];
                itemSource = {
                    date: entry.date,
                    voucherNo: firstConsumption.voucherNo,
                    itemName: firstConsumption.consume_items?.[0]?.itemName || "N/A",
                    totalAmount: ovarAllTotal,
                    info: "Consumption Info",
                };
            } else if (entry.production?.length > 0) {
                const firstProduction = entry.production[0];
                itemSource = {
                    date: entry.date,
                    voucherNo: firstProduction.voucherNo,
                    itemName: firstProduction.consume_items?.[0]?.itemName || "N/A",
                    totalAmount: ovarAllTotal,
                    info: "Production Info",
                };
            }

            return itemSource;
        });

        setReportData(filteredData);
    };

    // const filteredData = data.map((entry) => {
    //     let itemSource = null;

    //     // Check for consumption array first
    //     let consumptionTotalAmount = entry.consumption[0].totalAmount || 0;
    //     let productionTotalAmount = entry.production[0].totalAmount || 0;
    //     let ovarAllTotal = consumptionTotalAmount + "||" + productionTotalAmount;
    //     if (entry.consumption.length > 0) {
    //         const firstConsumption = entry.consumption[0];
    //         itemSource = {
    //             date: entry.date,
    //             voucherNo: firstConsumption.voucherNo,
    //             itemName: firstConsumption.consume_items[0]?.itemName || "N/A",
    //             totalAmount: ovarAllTotal,
    //             info: "Consumption Info",
    //         };
    //     }
    //     // If consumption is empty, check production array
    //     else if (entry.production.length > 0) {
    //         const firstProduction = entry.production[0];
    //         itemSource = {
    //             date: entry.date,
    //             voucherNo: firstProduction.voucherNo,
    //             itemName: firstProduction.consume_items[0]?.itemName || "N/A",
    //             totalAmount: ovarAllTotal,
    //             info: "Production Info",
    //         };
    //     }

    //     return itemSource;
    // });

    const fetchData = async () => {
        try {
            const response = await api(`consumption/get-consumptions`);
            const data = response.data;
            filterFun(data)
        } catch (error) {
            console.log(error)
        }
    }

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [])
    // Define columns
    const columns = useMemo(() =>
        [
            {
                accessorKey: 'date',
                header: 'Date',
                size: 300
            },
            {
                accessorKey: 'voucherNo',
                header: 'Voucher No.',
                size: 300
            },
            {
                accessorKey: 'itemName',
                header: 'Item Name',
                size: 450
            },
            {
                accessorKey: 'totalAmount',
                header: 'Total Amount',
                Cell: ({ cell }) => {
                    return `₹ ${cell.getValue()}`; // Format as currency
                },
                size: 300
            },
            {
                accessorKey: 'date',
                header: 'Info',
                Cell: ({ cell, row }) => (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => navigate(`/report-info/${cell.getValue()}`)}
                    >
                        Info
                    </Button>
                ),
                size: 300,
            },
        ], [reportData]);


    return (
        <div className='w-full flex justify-center'>
            <div className='w-[98%] mt-4'>
                <MaterialReactTable
                    columns={columns}
                    data={reportData || []}
                    enableRowSelection
                />
            </div>
        </div>
    );
};

export default Report;
