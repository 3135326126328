import { useEffect, useState } from "react";
import api from "./Axios/api";
import { MaterialReactTable } from "material-react-table";
import { useParams } from "react-router-dom";

const ReportInfo = () => {
    const { date } = useParams();
    const [data, setData] = useState({
        date: "",
        production: [],
        consumption: [],
    });

    const fetchData = async () => {
        try {
            const response = await api.get(
                `consumption/get-consumptions?insertDate=${date}`
            );
            console.log(response.data);
            setData(response.data[0] || {}); // Ensure data is an object
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columnsConsumption = [
        { header: "ITEM_NAME", accessorKey: "itemName" },
        { header: "GODOWN", accessorKey: "godown" },
        { header: "QUANTITY", accessorKey: "quantity" },
        { header: "RATE", accessorKey: "rate" },
        { header: "AMOUNT", accessorKey: "amount" },
    ];

    const columnsProduction = [
        { header: "ITEM_NAME", accessorKey: "itemName" },
        { header: "LOCATION", accessorKey: "godown" },
        { header: "QUANTITY", accessorKey: "quantity" },
        { header: "RATE", accessorKey: "rate" },
        { header: "AMOUNT", accessorKey: "amount" },
    ];

    return (
        <div className="w-full bg-white flex justify-center mt-4">
            <div className="w-[98%] mt-4">
                <div className="w-[50%] mx-auto flex justify-between">
                    <h2>Voucher Number - 123</h2>
                    <p>Date: {data.date}</p>
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                    {/* Consumption Table */}
                    <div style={{ flex: 1 }} className="mt-4">
                        <h3>Source (Consumption)</h3>
                        <MaterialReactTable
                            columns={columnsConsumption}
                            data={data.consumption[0]?.consume_items || []} // Fallback to an empty array
                        />
                    </div>

                    {/* Production Table */}
                    <div style={{ flex: 1 }} className="mt-4">
                        <h3>Destination (Production)</h3>
                        <MaterialReactTable
                            columns={columnsProduction}
                            data={data.production[0]?.consume_items || []} // Fallback to an empty array
                        />
                    </div>
                </div>

                <div className="mt-10 flex mx-auto w-[60%] justify-between">
                    <div>
                        <strong>Total Consumption Amount:</strong> ₹
                        {data.consumption[0]?.totalAmount || 0}
                    </div>
                    <div>
                        <strong>Total Production Amount:</strong> ₹
                        {data.production[0]?.totalAmount || 0}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportInfo;
