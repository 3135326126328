import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import api from "../Axios/api";


const AddCategory = () => {

    const [categoryData, setCategoryData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [inputs, setInputs] = useState({
        itemcat_title: "",
        itemcat_desc: "",
    })

    const getCategory = async () => {
        try {
            const response = await api.get(`/purchase-item-category/get`);
            setCategoryData(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error)
        }
    }


    const addCategory = async (e) => {
        try {
            e.preventDefault();
            const response = await api.post(`/purchase-item-category/add`, inputs);
            console.log(response.data);
            // getCategory();
            setCategoryData((prev) => [...prev, response.data]);
            resetHandler();
        } catch (error) {
            console.log(error)
        }
    }

    const editCategory = async (e, id) => {
        try {
            e.preventDefault();
            const response = await api.put(`/purchase-item-category/update/${id}`, inputs);
            console.log(response.data);
            // getCategory();
            setCategoryData((prev) => prev.map((item) => (item._id === id ? response.data : item)));
            resetHandler();
        } catch (error) {
            console.log(error)
        }
    }

    const deleteCategory = async (id) => {
        console.log(id);
        try {
            const response = await api.delete(`/purchase-item-category/delete/${id}`);
            console.log(response.data);
            // getCategory();
            setCategoryData((prev) => prev.filter((item) => item._id !== id));
        } catch (error) {
            console.log(error)
        }
    }

    const getOneCategory = async (id) => {
        try {
            const response = await api.get(`/purchase-item-category/get/${id}`);
            setInputs(response.data)
            setEditId(id);
        } catch (error) {
            console.log(error)
        }
    }

    const resetHandler = () => {
        setInputs({
            itemcat_title: "",
            itemcat_desc: "",
        });
        setEditId(null);
    };

    // Table Data
    // const data = [
    //     { id: 1, title: "Store Stock", desc: "All Store Stock", status: "Active" },
    //     { id: 2, title: "Packing Material", desc: "All Packing Material", status: "Active" },
    //     { id: 3, title: "Raw Material", desc: "All Raw Material", status: "Active" },
    // ];

    const columns = useMemo(() =>
        [
            {
                accessorKey: "itemcat_title",
                header: "Title",
            },
            {
                accessorKey: "itemcat_desc",
                header: "Description",
            },
            {
                accessorKey: "itemcat_added_on",
                header: "Date",
                Cell: ({ cell }) => <div>{cell.getValue().split('T')[0]}</div>
            },
            {
                accessorKey: "_id",
                header: "Actions",
                Cell: ({ cell }) => (
                    <div className="flex gap-2">
                        <button
                            variant="contained"
                            className="bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-md text-sm"
                            onClick={() => getOneCategory(cell.getValue())}
                        >
                            Edit
                        </button>
                        <button
                            variant="contained"
                            className="bg-red-500 text-white hover:bg-red-600 px-2 py-1 rounded-md text-sm"
                            onClick={() => deleteCategory(cell.getValue())}
                        >
                            Delete
                        </button>
                    </div>
                ),
            },
        ]
        , [])

    useEffect(() => {
        getCategory();
    }, [])

    return (
        <div className="flex flex-col lg:flex-row gap-6 p-6 max-h-[520px] ">

            {/* Table Section */}
            <div className="bg-white p-6 rounded-lg shadow-md lg:flex-grow overflow-y-scroll scrollbar-none">
                <h2 className="text-xl font-semibold mb-4 ">All Item Categories</h2>
                <MaterialReactTable
                    columns={columns}
                    data={categoryData}
                    muiTableProps={{
                        className: "w-full",
                    }}
                />
            </div>
            {/* Form Section */}
            <div className="bg-white p-6 rounded-lg shadow-md lg:w-1/3">
                <h2 className="text-xl font-semibold mb-4">{editId ? "Edit Category" : "Category Form"}</h2>
                <form
                    className="space-y-4"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (editId) {
                            editCategory(e, editId);
                        } else {
                            addCategory(e);
                        }
                    }}
                >
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Category Title*
                        </label>
                        <input
                            type="text"
                            placeholder="Enter Category Title"
                            onChange={(e) => setInputs((prev) => ({ ...prev, itemcat_title: e.target.value }))}
                            value={inputs.itemcat_title}
                            className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Description
                        </label>
                        <textarea
                            rows={4}
                            placeholder="Enter Description"
                            onChange={(e) => setInputs((prev) => ({ ...prev, itemcat_desc: e.target.value }))}
                            value={inputs.itemcat_desc}
                            className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        ></textarea>
                    </div>
                    {/* <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Category Status
                        </label>
                        <select className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
                            <option>Active</option>
                            <option>Inactive</option>
                        </select>
                    </div> */}
                    <div className="flex gap-4">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"

                        >
                            Submit
                        </button>
                        <button
                            type="reset"
                            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                            onClick={resetHandler}
                        >
                            Reset
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCategory;
