import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const IssueForm = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        issuedBy: '',
        issuedTo: '',
        date: '',
        storeSlNo: '',
        department: '',
        itemName: '',
        itemQuantity: '',
        placeOfUse: '',
        remark: '',
    });

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
    };

    return (
        <div>
            {/* Top Section */}
            <div className="flex justify-between items-center w-[98%] py-3 px-5 bg-white shadow-lg rounded-lg my-5 ml-6">
                <h1 className="text-2xl font-bold text-gray-800">Issue Form</h1>
                <button
                    className="bg-purple-500 text-white py-2 px-6 rounded-lg hover:bg-purple-600 focus:outline-none"
                    onClick={() => navigate('/stock-issue')}
                >
                    Back
                </button>
            </div>

            {/* Form Section */}
            <div className="w-[98%] p-6 bg-white shadow-lg rounded-lg mt-2 ml-6">
                <form onSubmit={handleSubmit}>
                    {/* General Details */}
                    <h2 className="text-xl font-semibold mb-4 text-gray-700">General Details</h2>
                    <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                        {/* Issued By */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">Issued By</label>
                            <input
                                type="text"
                                name="issuedBy"
                                value={formData.issuedBy}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter name of issuer"
                            />
                        </div>

                        {/* Issued To */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">Issued To</label>
                            <input
                                type="text"
                                name="issuedTo"
                                value={formData.issuedTo}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter recipient name"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-x-8 gap-y-6 mt-4">
                        {/* Date */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">Date</label>
                            <input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            />
                        </div>

                        {/* Store S.No. */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">Store S.No.</label>
                            <input
                                type="text"
                                name="storeSlNo"
                                value={formData.storeSlNo}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter store serial number"
                            />
                        </div>

                        {/* Department */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">Department</label>
                            <input
                                type="text"
                                name="department"
                                value={formData.department}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter department name"
                            />
                        </div>
                    </div>

                    {/* Item Detail */}
                    <h2 className="text-xl font-semibold my-6 text-gray-700">Item Detail</h2>
                    <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                        {/* Item Name */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">Item Name</label>
                            <select
                                name="itemName"
                                value={formData.itemName}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            >
                                <option value="" disabled>Select item</option>
                                <option value="Cement">Cement</option>
                                <option value="Sand">Sand</option>
                                <option value="Bricks">Bricks</option>
                                <option value="Steel">Steel</option>
                            </select>
                        </div>

                        {/* Item Quantity */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">Item Quantity</label>
                            <input
                                type="number"
                                name="itemQuantity"
                                value={formData.itemQuantity}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter quantity"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-x-8 gap-y-6 mt-4">
                        {/* Place of Use */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">Place of Use</label>
                            <textarea
                                name="placeOfUse"
                                value={formData.placeOfUse}
                                onChange={handleChange}
                                rows="3"
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter place of use"
                            />
                        </div>

                        {/* Remark */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">Remark</label>
                            <textarea
                                name="remark"
                                value={formData.remark}
                                onChange={handleChange}
                                rows="3"
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Add any remarks"
                            />
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="mt-4">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 focus:outline-none"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default IssueForm;
