import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { logout } from './redux/userSlice';
import { validateAccessToken } from './utils/checkToken';

const AuthRouter = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.staff_name);
    const navigate = useNavigate();

    useEffect(() => {
        const validate = () => {
            const isValid = validateAccessToken();
            if (!isValid) {
                dispatch(logout());
                Cookies.remove('access_token');
                navigate('/login');
            }
        };
        validate();
        const intervalID = setInterval(validate, 5000);
        return () => clearInterval(intervalID);
    }, [dispatch, navigate]);

    return (
        currentUser ? <Outlet /> : <Navigate to="/login" />
    );
};

export default AuthRouter;
